<template>
  <div class="pb-3">
    <v-layout row justify-space-between>
      <v-flex xs3>
        <v-text-field
          v-model="search"
          hide-details
          solo
          label="Search user"
          append-icon="search"
          @input="searchInput" 
        />
      </v-flex>
      <v-flex xs12 sm4 d-flex>
        <v-select
          v-model="sortBy"
          :items="items"
          item-text="name"
          item-value="value"
          label="Select"
          return-object
          solo
          hide-details
          @change="userSortBy"
        />
      </v-flex>
    </v-layout>

    <v-layout row justify-space-between>
      <v-flex xs12 sm8>
        <v-btn-toggle class="table-top-menu">
          <v-btn :to="{ name: 'users' }" flat large>
            All users
            <span
              v-if="newItem"
              class="count-new-users"
              v-text="newItem"
            ></span>
          </v-btn>

          <v-btn :to="{ name: 'blocked_users' }" flat large>
            Blocked users
          </v-btn>

          <v-btn :to="{ name: 'reported_users' }" flat large>
            Reported users
          </v-btn>

          <v-btn :to="{ name: 'model_users' }" flat large>
            Model users
            <span
              v-if="newModel"
              class="count-new-users"
              v-text="newModel"
            ></span>
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "UserListTopNav",
  data: () => ({
    inputSearchUserTimer: null,
    newUsersCount: null,
    search: null,
    sortBy: { name: "Sort by registration date", value: "created_at" },
    items: [
      { name: "Sort by registration date", value: "created_at" },
      { name: "Sort by alphabet", value: "username" },
      { name: "Sort by karma", value: "karma" }
    ]
  }),
  computed: {
    ...mapGetters(["newItem", "newModel"]),
  },
  created() {
    // this.$bus.on("new-users-count", data => {
    //   this.newUsersCount = data
    // })
  },
  destroyed() {
    // this.$bus.off("new-users-count", data => {
    //   this.newUsersCount = data
    // })
  },
  mounted() {
    let getRouterName = this.$route.name
    if (getRouterName === "reported_users") {
      let newObjSort = {
        name: "Sort by reported users",
        value: "reported_users"
      }
      this.items.push(newObjSort)
    } else if (getRouterName === "blocked_users") {
      let newObjSort = {
        name: "Sort by blocked users",
        value: "blocked_users"
      }
      this.items.push(newObjSort)
    }
    // this.$bus.emit("user-sort-by", this.sortBy.value);
  },
  methods: {
    userSortBy() {
      this.$bus.emit("user-sort-by", this.sortBy.value)
    },
    searchInput() {
      clearTimeout(this.inputSearchUserTimer)
      this.inputSearchUserTimer = setTimeout(() => {
        this.$bus.emit("user-search-input", this.search)
      }, 2000)
    }
  }
}
</script>

<style scoped>
.table-top-menu {
  width: 100%;
}

.table-top-menu .v-btn {
  height: 48px;
  flex-grow: 1;
  position: relative;
}

.v-btn.v-btn--active {
  pointer-events: none;
}
.count-new-users {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #f44336;
  border-radius: 50%;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
}
</style>
